@import '../../styles/index';

.image {
  display: block;
  width: 100%;
}

.caption {
  color: $color-black--100;
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.5;
  margin-inline: auto;
  margin-top: 1.5rem;
  padding-inline: 1.5rem;
  text-align: center;
  width: 100%;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}

.caption-with-download {
  max-width: 844px;
  text-align: left;

  div {
    display: flex;
    justify-content: space-between;
  }
}

.button-link {
  color: $color-surrogate--100;
  padding-left: 2rem;
}
