@import '../../styles/index';

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  position: relative;
}

.base {
  border-radius: $border-radius-default;
  width: 100%;
  padding: 2rem 1.5rem;

  @include media-from(md) {
    padding: 4rem;
  }

  .button {
    font-size: $font-size-18;
    box-shadow: none;
  }

  &.simple {
    background-color: $color-white--100;
    box-shadow: 0 16px 64px rgba(0, 0, 0, 0.08);
    color: $color-black--100;

    .button-link {
      color: $color-surrogate--100;
    }

    .text {
      color: $color-black--100;
    }
  }

  &.highlighted {
    background-color: $color-surrogate--100;
    color: $color-white--100;

    .button-link {
      color: $color-white--100;
    }

    .text {
      color: $color-white--100;

      ul li::before {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.483 20c-.435 0-.854-.171-1.159-.478L.464 13.64a1.572 1.572 0 01.023-2.252 1.644 1.644 0 012.294.026l4.54 4.555L19.082.635A1.643 1.643 0 0121.353.32c.715.529.86 1.528.321 2.23L8.78 19.365c-.284.37-.72.602-1.192.631-.036.002-.07.004-.104.004z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
      }
    }
  }

  &.side-by-side {
    max-width: none;

    @include media-from(md) {
      display: flex;

      .headline {
        width: calc(50% - 2rem);
        flex-shrink: 0;
        margin-right: 2rem;
      }
    }
  }
}

.headline {
  font-weight: $font-weight-bold;
  width: 100%;
  margin: 0 0 1rem 0;
  font-size: $font-size-30;
  line-height: 1.2;

  @include media-from(md) {
    font-size: $font-size-44;
    line-height: 1.1;
    margin: 0 0 1.5rem 0;
  }
}

.text {
  margin-bottom: 1.5rem;
  font-size: $font-size-18;

  @include media-from(md) {
    font-size: $font-size-20;
    margin-bottom: 2rem;
  }
}

.group {
  margin: 0;
}
