@import '../../../styles/index';

.base {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 25rem;
  color: $color-surrogate--03;
  z-index: -1;

  @include media-from(sm) {
    display: block;
  }
}

.icon {
  width: 100%;
  height: 100%;
}

.rocket {
  top: 6%;
  left: 51%;
  max-width: 25rem;
}

.calendar {
  top: 9%;
  left: 16%;
}

.search {
  top: -6%;
  left: 39%;
  transform: rotate(-68deg);
}

.thumbs-up {
  top: -8%;
  left: 54%;
  transform: rotate(-18deg);
}

.contract-amendents {
  top: 0;
  left: 50%;
}
