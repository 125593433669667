@import '../../styles/index';

.collapse-button {
  appearance: none;
  border: none;
  background-color: transparent;
  color: $color-surrogate--100;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: $font-weight-medium;
  font-size: $font-size-20;
  cursor: pointer;
  position: relative;
}

.chevron {
  transform: scaleY(1);
  transition: transform $duration-default;

  .collapsible-open & {
    transform: scaleY(-1);
  }
}

.filter-options-container {
  overflow: hidden;
  margin-top: 1.5rem;
  flex-basis: 100%;
}
