@import '../../styles/index';

.base {
  border-radius: inherit;
  overflow: hidden;
}

.image-stage {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.background-image {
  filter: blur(24px) brightness(0.75);
  height: 100%;
  position: absolute;
  transform: scale(1.125);
  width: 100%;
}

.image {
  display: flex;
  justify-items: center;
  margin: 0;
  width: 100%;

  picture {
    margin-inline: auto;
  }

  img {
    display: block;
    position: relative;

    @include media-from(md) {
      height: 600px;
    }
  }
}

.image-info {
  font-weight: $font-weight-light;
  padding: 1.25rem 1rem 1.25rem 1rem;

  @include media-from(md) {
    padding: 2.5rem 3rem 3rem 3rem;
  }
}

.button-icon {
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.button-mobile {
  align-items: center;
  border-top: 1px solid $color-surrogate--20;
  color: $color-surrogate--100;
  cursor: pointer;
  display: flex;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  justify-content: center;
  line-height: 1.28;
  padding: 1.25rem 1rem;
  transition: background-color $duration-default;
  width: 100%;

  &:hover {
    background-color: $color-surrogate--05;
  }

  @include media-from(md) {
    display: none;
  }
}
.button-desktop-wrapper {
  display: none;

  @include media-from(md) {
    display: block;
    position: relative;
    width: 100%;

    .button-desktop {
      position: absolute;
      right: 2rem;
      top: 0;
      transform: translateY(-50%);

      &-in-image {
        bottom: 0;
        top: unset;
      }
    }
  }
}

.title {
  background: linear-gradient(
    320deg,
    #{$color-red--100} 0%,
    #{$color-violet--100} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  font-size: $font-size-22;
  font-weight: $font-weight-medium;
  line-height: 1.33;
  margin-bottom: 0.75rem;
  -webkit-text-fill-color: transparent;

  @include media-from(md) {
    font-size: $font-size-24;
    margin-bottom: 1rem;
  }
}

.text {
  font-size: $font-size-16;
  line-height: 1.55;
  margin-bottom: 0.75rem;

  @include media-from(md) {
    font-size: $font-size-18;
    margin-bottom: 1rem;
  }
}

.legal {
  font-size: $font-size-16;
  line-height: 1.55;

  @include media-from(md) {
    font-size: $font-size-18;
  }
}
