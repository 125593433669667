@import '../../styles/index';

$gap: 15px;

.base {
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(8, 1fr);
  gap: $gap;
  height: 500px;
}

.base > * {
  background: lightgray;
}

.item {
  border-radius: $border-radius-default;
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  position: relative;
}

.item1 {
  display: none;
}

.item2 {
  grid-column: 1/5;
  grid-row: 1/6;
}

.item3 {
  grid-column: 5/9;
  grid-row: 2/6;
}

.item4 {
  display: none;
}

.item5 {
  grid-column: 1/6;
  grid-row: 6/9;
}

.item6 {
  grid-column: 6/9;
  grid-row: 6/10;
}

@media screen and (min-width: 689px) {
  .base {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(14, 1fr);
    height: 550px;
    gap: 20px;
  }

  .item1 {
    grid-column: 1/5;
    grid-row: 3/7;
    display: block;
  }

  .item2 {
    grid-column: 5/8;
    grid-row: 1/5;
  }

  .item3 {
    grid-column: 8/11;
    grid-row: 2/5;
  }

  .item4 {
    grid-column: 11/15;
    grid-row: 3/7;
    display: block;
  }

  .item5 {
    grid-column: 5/9;
    grid-row: 5/9;
  }

  .item6 {
    grid-column: 9/11;
    grid-row: 5/8;
  }
}

.magnolia {
  .item::before {
    content: ' ';
    color: $color-white--100;
    font-size: 3rem;
    font-weight: 800;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
  }

  .item1::before {
    content: '1';
  }

  .item3::before {
    content: '3';
  }

  .item2::before {
    content: '2';
  }

  .item4::before {
    content: '4';
  }

  .item5::before {
    content: '5';
  }

  .item6::before {
    content: '6';
  }
}
