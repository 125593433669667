@import '../../../styles/index';

.base {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $color-surrogate--20;
  transition: background-color 200ms ease;
  cursor: pointer;
  z-index: $index-timeline-item;

  @include media-from(sm) {
    width: 1.5rem;
    height: 1.5rem;
    border: 7px solid $color-surrogate--100;
    background-color: $color-white--100;
    transition: border-color 500ms ease;
  }
}

.active {
  background-color: $color-surrogate--100;

  @include media-from(sm) {
    background-color: $color-white--100;
    transition-delay: 500ms;

    ~ .base {
      border-color: $color-surrogate--20;
    }
  }
}
