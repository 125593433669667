@import '../../styles/index';

$text-container-padding: 1rem;
$download-button-leave-space: 2.875rem;

.base {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: $shadow--large;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
}

.text-and-download-container {
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: 1.33;
  padding: 0 $text-container-padding;
  flex-grow: 1;
  word-wrap: break-word;
  hyphens: auto;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 1.4;
  }

  .title {
    display: block;
    padding: $text-container-padding 0;
  }

  .number {
    font-weight: $font-weight-light;
    font-size: $font-size-14;
    display: block;
    @include media-from(sm) {
      font-size: $font-size-16;
    }
  }

  .download-button-wrapper {
    position: relative;
    float: right;
    width: $download-button-leave-space;

    .download-button {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-50%);
    }
  }
}

.info-button {
  appearance: none;
  border: none;
  border-top: 1px solid $color-surrogate--20;
  background-color: transparent;
  width: 100%;
  padding: 1rem;
  transition: background-color $duration-default;
  color: $color-surrogate--100;
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: 1.28;
  cursor: pointer;
  margin-top: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 1.25;
  }

  &:hover {
    background-color: $color-surrogate--05;
  }
}
