@import '../../styles/index';

.base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  @include media-from(sm) {
    justify-content: space-between;
    padding: 0 0.75rem;
  }
}

.progress-bar {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 0.25rem;
  background-color: $color-surrogate--10;
  z-index: $index-timeline-progress;
  overflow: hidden;

  &-fill {
    width: 100%;
    height: 100%;
    transform-origin: left center;
    transition: transform 500ms ease-in-out;
    background: $gradient-enercity;
  }

  @include media-from(sm) {
    margin: auto 0.75rem;
    width: calc(100% - 1.5rem);
    display: block;
  }
}

.chevron {
  display: flex;
  align-items: center;
  color: $color-surrogate--20;

  &.active {
    color: $color-surrogate--100;
    cursor: pointer;
  }

  @include media-from(sm) {
    display: none;
  }
}
